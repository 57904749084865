.ficheDetail.MuiCard-root {
  border-radius: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1em;
}

.MuiCardMedia-root {
  padding: 5%;
  vertical-Align: top;

}

a {
  color: #545454;
  font-size: 1.8rem;
  font-weight: 500;
}

a:hover {
  cursor: pointer;
  color: #2da9c4;
}

span {
  white-space: nowrap;
}

.titreLien {
  line-height: 0.5em;
}

.txt {
  text-align: justify;
}