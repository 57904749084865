.titre.MuiTypography-root {
    text-align: left;
    font-size: 4em;
    border-left: solid 8px #808080;
    padding: 0.5em;
}

a {
    color: #808080;
    letter-spacing: 2.5px;
    text-decoration-line: none;
    cursor: pointer;
}

a>.MuiTypography-root {
    font-size: 2em;
    font-weight: 300;
}

.txt {
    color: #808080;
    margin-left: 7%;
    margin-top: 7%;
    margin-right: 10%;
    margin-bottom: 5%;
}

.txt span {
    white-space: nowrap;
}

.paragraphe {
    color: #545454;
    text-align: justify;
    font-size: 1.1em;
}

.typo_1,
.typo_2,
.typo_3 {
    width: 600px;
    padding: 2%;
}

.typo_1 {
    margin-left: 23% !important;
}

.typo_2 {
    margin-left: 10% !important;
}

.typo_3 {
    margin-left: 17% !important;
}

.blod {
    color: #545454;
    font-weight: bold;
    white-space: nowrap;
    /*    font-size: 1.1em; */
}

.enterSite {
    margin-left: 40%;
    margin-top: 5%;
}

.bouton2>a>p {
    font-size: 1.4em;
}

.bouton2:hover,
.bouton:hover {
    cursor: pointer;
    color: #2da9c4;
}