:root {
    --select: white;
    --unselect: black;
}

.MuiTypography-root>a,
.MuiButtonBase-root>span {
    color: white;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-decoration-line: none;
    cursor: pointer;
    margin-right: 10px;
}


.css-1g4kb8l-MuiTypography-root p {
    color: black;
}

/*
.css-92euia-…graphy-root {
    container-name: firstLang;
}

@container firstLang (color::active:white) {
    .css-92euia-…graphy-root {
        color: black;
    }
}
*/