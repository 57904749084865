.login {
    width: 18em;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0.5em;
    transform: translate(-50%, -50%);
    border-radius: 1em !important;
}

.login {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
    padding-left: 2em;
    color: #545454;
    border-radius: 1em;
}

.login form {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 16em;
    margin: auto;
    color: #808080;
}

.MuiPaper-root form .button {
    text-align: right;
    width: 11em;
    background-color: #808080;
    color: white;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1.5px;
    margin-top: 0;
    margin-left: auto;

}

.MuiPaper-root form .button {
    background-color: #808080;
}